import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../CSS/Merch.css'; // Import your CSS for styling

const Merch = ({ isPayPalScriptLoaded }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch the products from BigCartel
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://api.bigcartel.com/whatsinthebox/products.json');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const baseURL = "https://whatsinthebox.bigcartel.com/";

  return (
    <div id="merch">
      <h2>Out Of The Box Merch</h2>
        <div className="products">
            {products.map((product) => (
            <div key={product.id} className="product">
                <img src={product.images[0].url} alt={product.name} />
                <h3>{product.name}</h3>
                <a href={`${baseURL}${product.url}`} target="_blank" rel="noopener noreferrer">Buy Now</a>
            </div>
            ))}
        </div>
        
    </div>
  );
};

export default Merch;