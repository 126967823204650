import React from 'react';
import { FaInstagram, FaYoutube } from 'react-icons/fa';
import Logo from '../Assets/Logo.png';
import '../CSS/Footer.css';

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-column">
        
        <div className="social-media">
          <a href="https://www.instagram.com/whatsintheboxny" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="instagram-icon" /> WhatsInTheBoxNY
          </a>
          <a href="https://www.youtube.com/@WhatsInTheBoxNY" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="youtube-icon" /> YouTube
          </a>
        </div>
      </div>

      <div className="footer-column footer-logo">
        <img src={Logo} alt="Band Logo" />
      </div>

      <div className="footer-column">
        <div className="contact-info">
          <p>Booking: <span className="email">WhatsInTheBoxNY<span className="special-font">@</span>gmail.com</span></p>
        </div>

        <div className="copyright">
          <p><span className="special-font">&copy;</span> {new Date().getFullYear()} What's In The Box. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;