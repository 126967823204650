import React, { useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import Logo from '../Assets/Logo.png';
import '../CSS/Sidebar.css';

const Sidebar = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" expanded={expanded}>
      <Container fluid>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded => !expanded)} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="flex-column">
            <img id="SideLogo" src={Logo} alt="Logo" />
            <Nav.Link href="#home" onClick={() => setExpanded(false)}>Home</Nav.Link>
            <Nav.Link href="#gallery" onClick={() => setExpanded(false)}>Gallery</Nav.Link>
            <Nav.Link href="#about" onClick={() => setExpanded(false)}>About</Nav.Link>
            <Nav.Link href="#events" onClick={() => setExpanded(false)}>Events</Nav.Link>
            <Nav.Link href="#merch" onClick={() => setExpanded(false)}>Merch</Nav.Link>
            <Nav.Link href="#footer" onClick={() => setExpanded(false)}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;